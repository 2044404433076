import React, { useEffect } from 'react';
import './App.css';
import { HashRouter as Router, withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Home from './container/Home.js';
import Dashboard from './container/Dashboard.js';
import AssignedOrders from './container/AssignedOrders.js';
import DeliveredOrders from './container/DeliveredOrders.js';
import MyRoute from './container/MyRoute.js';
import OrderDetails from './container/OrderDetails.js';
import Profile from './container/Profile.js';
import MapContainer from './container/MapContainer.js';
// import NoPage from './container/NoPage';

function App() {

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/assignedorder" component={AssignedOrders} />
          <Route path="/deliveredorder" component={DeliveredOrders} />
          <Route path="/myroute" component={MyRoute} />
          <Route path="/orderdetails/:order_id" component={OrderDetails} />
          <Route path="/map-container/:lat/:lon/:map" component={MapContainer} />
          <Route path="/profile" component={Profile} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;

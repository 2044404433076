import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import swal from 'sweetalert';
import Header from '../component/Header';
import Axios from 'axios';
import { api_url } from '../Config';
class Profile extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            loading: true,
            name: "",
            email: "",
            phone: "",
            password: "",
            repassword: "",
        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    submitForm(event) {
        event.preventDefault();
        const functions = this;

        let user_id = localStorage.getItem('rider_id') ? localStorage.getItem('rider_id') : 1;
        if (this.state.password !== "" && this.state.repassword === "") {
            swal("Confirm password required!");
            return;
        }
        if (this.state.password !== this.state.repassword) {
            swal("Password not matched!");
            return;
        }
        this.setState({ loading: true })
        Axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.state.base_url + 'api/rider/user/info/update',
            data: {
                email: this.state.email,
                password: this.state.password,
                name: this.state.name,
                phone: this.state.phone,
                user_id: user_id
            }
        }).then(function (response) {
            functions.setState({ loading: false })
            if (response.data.status === 200) {
                swal(response.data.message);
                localStorage.setItem('user_name', functions.state.name)
            } else {
                swal("Error occured try again later!");
            }
        });
    }
    componentDidMount() {
        this.setState({ loading: true });
        let user_id = localStorage.getItem('rider_id') ? localStorage.getItem('rider_id') : 1;
        var sendURl = this.state.base_url + 'api/rider/user/info_api/' + user_id;
        fetch(sendURl)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    profile: result.data[0],
                    name: result.data[0].name,
                    phone: result.data[0].phone,
                    email: result.data[0].email,
                    loading: false
                })

            });
        function readURL(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $('#imagePreview').css('background-image', 'url(' + e.target.result + ')');
                    $('#imagePreview').hide();
                    $('#imagePreview').fadeIn(650);
                }
                reader.readAsDataURL(input.files[0]);
            }
        }
        $("#imageUpload").change(function () {
            readURL(this);
        });
    }
    componentWillMount() {
        if (!localStorage.getItem("rider_id")) {
            this.setState({ redirectToReferrer: true });
        }
        else {
            this.getUserData();
        }
    }
    getUserData() {
        JSON.parse(localStorage.getItem("rider_id"));

    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { loading } = this.state;
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/' />)
        }
        return (
            <React.Fragment>
                <body className="login_Bg">
                    <Header title="Profile" tagline="" />
                    <section class="login_box user_registration">
                        <div class="main_wrap">
                            <form onSubmit={this.submitForm} method="POST">
                                {/* <div class="avatar-upload">
                            <div class="avatar-edit">
                                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                                <label for="imageUpload"></label>
                            </div>
                            <div class="avatar-preview">
                                <div id="imagePreview" >
                                </div>
                            </div>
                        </div> */}
                                <div class="form_box">
                                    <span class="lnr lnr-user"></span>
                                    <input type="text" placeholder="Name" required="required" onChange={this.onChange} name="name" defaultValue={this.state.name} />
                                </div>
                                <div class="form_box">
                                    <span class="lnr lnr-phone-handset"></span>
                                    <input type="text" placeholder="Phone No" required="required" onChange={this.onChange} name="phone" defaultValue={this.state.phone} />
                                </div>
                                <div class="form_box">
                                    <span class="lnr lnr-envelope"></span>
                                    <input type="email" placeholder="Email" required="required" onChange={this.onChange} name="email" defaultValue={this.state.email} />
                                </div>
                                <div class="form_box">
                                    <span class="lnr lnr-lock"></span>
                                    <input type="password" placeholder="Password" onChange={this.onChange} name="password" class="" />
                                </div>
                                <div class="form_box">
                                    <span class="lnr lnr-lock"></span>
                                    <input type="password" placeholder="Repeat Password" onChange={this.onChange} name="repassword" class="" />
                                </div>
                                <div class="form_box">
                                    <button
                                        class="login_btn"
                                        type="submit"
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {loading && 'Loading...'
                                        }
                                        {!loading && 'Submit'}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </section>
                </body>

            </React.Fragment>
        )
    }
}
export default Profile;

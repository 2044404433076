import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import checkbox from '../assets/images/checkbox-icon.png';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { api_url } from '../Config';
import Axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import loader from '../assets/images/favicon-icon.png';
class OrderDetails extends Component {
  constructor() {
    super();
    this.state = {
      base_url: api_url.base_url,
      order: [],
      order_detail: [],
      loading: true,
      loading_detail: true,
      status: "Pending",
      loadingBtn: false,
      orderslength: 0
    }
    this.deliverOrder = this.deliverOrder.bind(this);
    this.getOrderDetail = this.getOrderDetail.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
  }
  closeOverlay() {
    $(".overlay_order,.overlay_order_info").fadeOut();
  }
  deliverOrder() {
    let id = this.props.match.params.order_id;
    let user_id = localStorage.getItem('rider_id') ? localStorage.getItem('rider_id') : 1;
    this.setState({ loadingBtn: true })
    let functions = this;
    Axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: this.state.base_url + 'api/rider/order/update_status',
      data: {
        rider_id: user_id,
        order_id: id,
        status: 'delivered'
      }
    }).then(function (response) {
      console.log(response)
      functions.setState({ loadingBtn: false })
      if (response.data.code == "200") {
        $(".overlay_order,.overlay_order_info").fadeIn();
        functions.getOrderDetail();
      } else {
        swal("Error occured try again later");
      }
    });
  }
  componentDidMount() {
    this.getOrderDetail();
  }
  getOrderDetail() {
    this.setState({ loading: true });
    this.setState({ loading_detail: true });
    let id = this.props.match.params.order_id;
    var sendURl = this.state.base_url + 'api/v2/order/' + id;
    fetch(sendURl)
      .then(res => res.json())
      .then(result => {
        // console.log(result.data[0].lon)
        if (result.data[0].lat > 0 && result.data[0].lon > 0) {
          this.setState({
            order: result.data,
            loading: false,
            status: result.data[0].order_status,
            orderslength: 1
          })
        } else {
          this.setState({
            order: result.data,
            loading: false,
            status: result.data[0].order_status,

          })
        }
      });
    var detailURL = this.state.base_url + 'api/rider/order_detail/' + id;
    fetch(detailURL)
      .then(res => res.json())
      .then(result => {
        this.setState({
          order_detail: result.data,
          loading_detail: false
        })

      });
  }
  componentWillMount() {
    if (!localStorage.getItem("rider_id")) {
      this.setState({ redirectToReferrer: true });
    }
    else {
      this.getUserData();
    }
  }
  getUserData() {
    JSON.parse(localStorage.getItem("rider_id"));

  }
  render() {
    const { loadingBtn } = this.state;
    let hrefUrl = '';
    if (this.state.orderslength > 0) {
      let lat = this.state.order[0].lat ? this.state.order[0].lat : '';
      let lon = this.state.order[0].lon ? this.state.order[0].lon : '';
      hrefUrl = "http://maps.google.com/?q=" + lat + "," + lon;
    }
    if (this.state.redirectToReferrer) {
      return (<Redirect to='/' />)
    }
    function paymentType(payment) {
      if (payment === 'cash_on_delivery') {
        return 'Cash On Delivery';
      } else {
        return payment
      }
    }
    return (
      <React.Fragment>
        <body className="login_Bg">
          <Header title="Order Details" tagline="" />
          <section className="login_box">
            <div className="order_details searchbar myroute_search" id="order_details_table">
              <div className="info_order">
                <h4>Customer Information</h4>
                {
                  this.state.loading ?
                    <div className="loading_img checkout_loaders">
                      <img src={loader} alt="loader" />
                    </div>
                    :
                    <table className="table">

                      <tr>
                        <th>Name</th>
                        <td>{this.state.order[0].shipping_address_json.name}</td>
                      </tr>
                      <tr>
                        <th>Phone</th>
                        <td style={{ fontSize: '14px' }}><a target="_blank" href={`tel:${this.state.order[0].shipping_address_json.phone}`} className="phone_tel">{this.state.order[0].shipping_address_json.phone}</a></td>
                      </tr>
                      <tr>
                        <th>Shipping Address</th>{
                          this.state.orderslength > 0 ?
                            <Link to={`/map-container/${this.state.order[0].lat}/${this.state.order[0].lon}/${this.state.order[0].shipping_address_json.address}`}>
                              <td className="Address_icon"><a >{this.state.order[0].shipping_address_json.address} <i className="fa fa-map-marker"></i></a></td>
                            </Link>

                            :
                            <td className="Address_icon"> {this.state.order[0].shipping_address_json.address} <i className="fa fa-map-marker"></i></td>
                        }
                        {/* <td className="Address_icon"><a target={hrefUrl ? "_blank" : ''} href={hrefUrl}>{this.state.order[0].shipping_address_json.address} <i className="fa fa-map-marker"></i></a></td> */}
                      </tr>
                    </table>
                }

              </div>
              <div className="info_order">
                <h4>Order Information</h4>
                {
                  this.state.loading ?
                    <div className="loading_img checkout_loaders">
                      <img src={loader} alt="loader" />
                    </div>
                    :

                    <table className="table">
                      <tr>
                        <th>Order  Code</th>
                        <td>{this.state.order[0].code}</td>
                      </tr>
                      <tr>
                        <th>Order Date</th>
                        <td>{this.state.order[0].created_at}</td>
                      </tr>
                      <tr>
                        <th> Time Selected</th>
                        <td style={{ fontSize: '11px' }} >{this.state.order[0].time_slot}</td>
                      </tr>
                      <tr>
                        <th>Order Status</th>
                        <td>{this.state.order[0].order_status}</td>
                      </tr>
                      <tr>
                        <th>Total  amount</th>
                        <td style={{ fontWeight: '700', fontSize: '17px', color: '#591845' }}>Rs:{this.state.order[0].grand_total}</td>
                      </tr>
                      <tr>
                        <th>Payment Method</th>
                        <td>{paymentType(this.state.order[0].payment_type)}</td>
                      </tr>
                    </table>
                }

              </div>

              <div className="info_order">
                <h4>Order Details</h4>
                {
                  this.state.loading_detail ?
                    <div className="loading_img checkout_loaders">
                      <img src={loader} alt="loader" />
                    </div>
                    :
                    this.state.order_detail.length > 0

                      ?
                      this.state.order_detail.map(detail => {
                        return (
                          <table className="table" key={detail.id}>
                            <tr>
                              <th>Product</th>
                              <td>{detail.product_name}</td>
                            </tr>
                            <tr>
                              <th>Quantity</th>
                              <td>{detail.quantity}</td>
                            </tr>
                            <tr>
                              <th>Price</th>
                              <td>Rs:{detail.price}</td>
                            </tr>
                          </table>
                        );
                      })
                      :
                      <span style={{ color: '#fff' }}>No Record Found</span>

                }
              </div>


            </div>
          </section>


          {
            this.state.status !== "Delivered" &&
            <div className="delivery_now">
              <button
                type="button"
                onClick={this.deliverOrder}
                disabled={loadingBtn}
              >
                {loadingBtn && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {loadingBtn && "Loading...."
                }
                {!loadingBtn && 'Deliver Now'}
              </button>
            </div>
          }

          <div className="overlay_order" onClick={this.closeOverlay}></div>
          <div className="overlay_order_info">
            <div className="success">
              <img src={checkbox} alt="" />
              <h3>Order Delivered Successfully</h3>
            </div>
          </div>
        </body>


        <Footer />
      </React.Fragment >
    )
  }
}
export default OrderDetails;

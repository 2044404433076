import React, { Component } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Redirect } from 'react-router';
export class MapContainer extends Component {
  constructor() {
    super();
    this.state = {
      redirectUrl: '',
      redirettoReferer: false
    };
    this.onMapClicked = this.onMapClicked.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }
  onMarkerClick(props, marker, e) {
    console.log(props)
    this.setState({
      redirectUrl: `http://maps.google.com/?q=${props.mapCenter.lat},${props.mapCenter.lng}`,
      redirettoReferer: true
    })
  }
  onMapClicked(mapProps, map, clickEvent) {
    console.log(map)

  }
  render() {
    if (this.state.redirettoReferer) {
      window.open(this.state.redirectUrl);
    }
    let lat = this.props.match.params.lat;
    let lon = this.props.match.params.lon;
    let map = this.props.match.params.map;
    return (
      <body className="login_Bg">
        {/* <Header title="Google Map" tagline="" /> */}
        <Map
          google={this.props.google}
          initialCenter={{
            lat: lat,
            lng: lon
          }}
          zoom={15}
          onClick={this.onMapClicked}
        >
          <Marker onClick={this.onMarkerClick}
            name={map} />

        </Map>
        {/* <Footer /> */}
      </body>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: ("AIzaSyBkYkD1OeulFTr2F-qEFCHcX8-KtuBtwU8")
})(MapContainer)
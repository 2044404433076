import React from 'react';
import { Link, } from 'react-router-dom';
const Footer = () => {
   return (
      <div class="bottom_bar">
         <ul>
            <li id="home_icon">
               <Link to="/dashboard">
                  <span class="lnr lnr-home"></span>
                  <b>Dashboard</b>
               </Link>
            </li>
            <li>
               <Link to="/assignedorder">
                  <span class="lnr lnr-file-empty"></span>
                  <b>Assigned</b>
               </Link>
            </li>
            <li>
               <Link to="/profile">
                  <span class="lnr lnr-user"></span>
                  <b>Profile</b>
               </Link>
            </li>
         </ul>
      </div>
   )
}

export default Footer

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import loader from '../assets/images/favicon-icon.png';
import { api_url } from '../Config';
class AssignedOrders extends Component {
   constructor() {
      super();
      this.state = {
         base_url: api_url.base_url,
         orders: [],
         loading: false
      }
   }
   componentDidMount() {
      this.setState({ loading: true });
      let user_id = localStorage.getItem('rider_id') ? localStorage.getItem('rider_id') : 1;
      var sendURl = this.state.base_url + 'api/rider/assigned-orders/' + user_id;
      fetch(sendURl)
         .then(res => res.json())
         .then(result => {
            this.setState({
               orders: result.data,
               loading: false
            })

         });
      this.setState({ loading: true });
   }
   componentWillMount() {
      if (!localStorage.getItem("rider_id")) {
         this.setState({ redirectToReferrer: true });
      }
      else {
         this.getUserData();
      }
   }
   getUserData() {
      JSON.parse(localStorage.getItem("rider_id"));

   }
   render() {
      if (this.state.redirectToReferrer) {
         return (<Redirect to='/' />)
      }
      return (
         <React.Fragment>
            <body className="login_Bg">
               <Header title="Assigned Orders" tagline="" />
               <section className="login_box listing_view" id="assigned_order_bg">
                  <div className="main_wrap dashboard_box">
                     <div className="searchbar">
                        <input type="text" placeholder="Search..." name="" />
                        <span className="lnr lnr-magnifier"></span>
                     </div>
                     {
                        this.state.loading ?
                           <div className="loading_img checkout_loaders">
                              <img src={loader} alt="loader" />
                           </div>
                           :
                           this.state.orders.length > 0 ?

                              this.state.orders.map(order => {
                                 return (
                                    <div className="assigned__order" key={order.id}>
                                       <div className="inner_assigned">
                                          <Link to={`/orderdetails/${order.id}`}>
                                             <p className="orderno">#{order.code} <span>{order.time_slot}</span> </p>
                                             <p className="numberbox"><i className="lnr lnr-user"></i> {order.shipping_address_json.name}<span ><i className="lnr lnr-phone-handset"></i>
                                                <a  >{order.shipping_address_json.phone}</a></span></p>
                                             <p className="address"><i className="lnr lnr-map-marker"></i> {order.shipping_address}</p>
                                          </Link>
                                       </div>
                                    </div>
                                 );
                              })
                              :
                              <span style={{ color: '#fff' }}>No Record Found</span>
                     }


                  </div>
               </section>
            </body>


            <Footer />
         </React.Fragment>
      )
   }
}
export default AssignedOrders;

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { api_url } from '../Config';
class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      base_url: api_url.base_url,
      redirectToReferrer: false,
      wallet_balance: 0,
      delivered_count: 0,
      assigned_count: 0
    }
  }
  componentDidMount() {
    this.setState({ loading: true });
    let user_id = localStorage.getItem('rider_id') ? localStorage.getItem('rider_id') : 1;
    var assignedURL = this.state.base_url + 'api/rider/assigned-orders-count/' + user_id;
    fetch(assignedURL)
      .then(res => res.json())
      .then(result => {
        this.setState({
          assigned_count: result.orders
        })

      });
    var deliveredURL = this.state.base_url + 'api/rider/delivered-orders-count/' + user_id;
    fetch(deliveredURL)
      .then(res => res.json())
      .then(result => {
        this.setState({
          delivered_count: result.orders
        })

      });
    var sendURl = this.state.base_url + 'api/rider/wallet_balance/' + user_id;
    fetch(sendURl)
      .then(res => res.json())
      .then(result => {
        this.setState({
          wallet_balance: result.wallet_amount,
          loading: false
        })

      });
  }
  componentWillMount() {
    if (!localStorage.getItem("rider_id")) {
      this.setState({ redirectToReferrer: true });
    }
    else {
      this.getUserData();
    }
  }
  getUserData() {
    JSON.parse(localStorage.getItem("rider_id"));

  }
  render() {
    if (this.state.redirectToReferrer) {
      return (<Redirect to='/' />)
    }
    return (
      <React.Fragment>
        <body className="login_Bg">
          <Header title="Dashboard" tagline="" />
          <section className="login_box ">
            <div className="main_wrap dashboard_box dash_board_page">
              <p><b>Dashboard</b> Let's get a quick overview...</p>
              <div className="assigned_order">
                <ul>
                  <li>
                    <Link to="/assignedorder">
                      <b className="assigned_col">Assigned</b>
                      <p>{this.state.assigned_count}</p>
                      <span className="lnr lnr-dice"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/deliveredorder">
                      <b className="deliver_col">Delivered</b>
                      <p>{this.state.delivered_count}</p>
                      <span className="lnr lnr-history"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <b className="deliver_col">Wallet Balance</b>
                      <p>{this.state.wallet_balance}</p>
                      <span>
                        <svg id="Wallet_bln" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M4 3a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-1.77A3 3 0 0 0 21 15v-5a3 3 0 0 0-1-2.23V6a3 3 0 0 0-3-3H4zm0 1h13a2 2 0 0 1 2 2v1.174A3 3 0 0 0 18 7h-6a3 3 0 0 0-3 3v5a3 3 0 0 0 3 3h6a3 3 0 0 0 1-.174V19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm8 4h6a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2zm2.5 2a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zm0 1a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3z" fill="currentColor" /></svg>

                      </span>
                    </Link>
                  </li>
                </ul>
              </div>


            </div>

          </section>
        </body>


        <Footer />
      </React.Fragment>
    )
  }
}
export default Dashboard;

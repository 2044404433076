import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import favicon from '../assets/images/favicon.png';
import $ from 'jquery';
import { ProductConsumer } from "../context";
class Header extends Component {
  constructor() {
    super();
    this.state = {
      redirectToReferrer: false
    }

    this.logout = this.logout.bind(this);
  }
  logout() {
    localStorage.clear();
    this.setState({ redirectToReferrer: true })
  }
  componentDidMount() {



    $(".menubox span").click(function () {
      $(".sidebar_box,.overlay_body").fadeIn();
    });



    $(".overlay_body,.close_btn").click(function () {
      $(".sidebar_box,.overlay_body").fadeOut();
    });



    $(".icon_order").click(function () {
      $(".order_list_sidebar").show();
      $(".icon_order_hide").show();
    });

    $(".icon_order").click(function () {
      $(".icon_order").hide();
    });

    $(".icon_order_hide").click(function () {
      $(".order_list_sidebar").hide();
      $(".icon_order").show();
    });

    $(".delivery_now a").click(function () {
      $(".overlay_order,.overlay_order_info").fadeIn();
    });
    $(".close_btn,overlay_order").click(function () {
      $(".overlay_order,.overlay_order_info").fadeOut();
    });


  }
  render() {
    const user_name = localStorage.getItem('user_name') ? localStorage.getItem('user_name') : "Guest User";
    if (this.state.redirectToReferrer) {
      return (<Redirect to='/' />)
    }
    return (
      <ProductConsumer>
        {value => {
          return (
            <React.Fragment>


              <section className="sidebar_box">
                <div className="menu_head">
                  <div className="close_btn">
                    <i className="lnr lnr-cross"></i>
                  </div>
                  <img src={favicon} alt="" />
                  <h4>Salam, {user_name}!</h4>
                </div>
                <div className="navbar_items">
                  <ul>
                    <li>
                      <Link to="/dashboard">Dashboard</Link>
                      <i className="lnr lnr-home"></i>
                    </li>
                    {/* <li>
        <Link to="/myroute">My Route</Link>
        <i className="lnr lnr-map-marker"></i>
      </li> */}
                    <li>
                      <Link to="/profile">Profile</Link>
                      <i className="lnr lnr-user"></i>
                    </li>
                    <li>
                      <Link to="/assignedorder">Assigned Orders</Link>
                      <i className="lnr lnr-dice"></i>
                    </li>
                    <li>
                      <Link to="/deliveredorder">Delivered Orders</Link>
                      <i className="lnr lnr-history"></i>
                    </li>

                    <li>
                      <a href="#" onClick={this.logout}>Logout</a>
                      <i className="lnr lnr-power-switch"></i>
                    </li>

                  </ul>
                </div>
              </section>
              <div className="overlay_body"></div>

              <section className="top_bar">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 pd-0 menubox">
                      <Link to="#"><span class="lnr lnr-menu"></span></Link>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-3 pd-0 main_head">
                      <h4>{this.props.title}</h4>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 pd-0 logo_box">
                      <Link to="#"><img src={favicon} alt="" /></Link>
                    </div>
                  </div>
                </div>
              </section>

            </React.Fragment>
          );
        }}
      </ProductConsumer>
    )
  }
}
export default Header;
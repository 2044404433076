import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from 'jquery';
import logobox from '../assets/images/white-loader.png';
import favicon from '../assets/images/favicon.png';
import { api_url } from '../Config';
import Axios from 'axios';
import swal from 'sweetalert';
class Home extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            emailErr: '',
            passwordErr: '',
            loadingBtn: false,
            base_url: api_url.base_url,
            redirectToReferrer: false,
        }
        this.submitForm = this.submitForm.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        // $(window).load(function() {
        $(".loader").delay(3400).fadeOut("slow");
        $("#overlayer").delay(3400).fadeOut("slow");
        // });
        // $(window).load(function() {
        $(".loader").delay(3400).fadeOut("slow");
        $("#overlayer").delay(3400).fadeOut("slow");
        // });
    }
    componentWillMount() {
        if (localStorage.getItem("rider_id")) {
            this.setState({ redirectToReferrer: true });
        }
        else {
            this.getUserData();
        }
    }
    getUserData() {
        JSON.parse(localStorage.getItem("rider_id"));

    }
    submitForm(event) {
        event.preventDefault();
        if (this.state.email === '' || this.state.password === '') {
            swal('Email and Password required.')
        } else {
            this.setState({ loadingBtn: true })
            let functions = this;
            Axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.state.base_url + 'api/rider/auth/loginWithRider',
                data: {
                    email: this.state.email,
                    password: this.state.password
                }
            }).then(function (response) {
                console.log(response)
                functions.setState({ loadingBtn: false })
                if (response.data.user) {
                    if (response.data.response === 200) {
                        localStorage.setItem("rider_id", response.data.user.id);
                        localStorage.setItem("type", response.data.user.type);
                        localStorage.setItem("user_name", response.data.user.name);
                        localStorage.setItem("email", response.data.user.email);
                        localStorage.setItem("approved", 1);
                        functions.setState({
                            redirectToReferrer: true,
                        })
                    } else {
                        swal("Email or password is invalid");
                    }
                } else {
                    swal("Email or password is invalid");
                }
            });

        }

    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/dashboard' />)
        }
        const { loadingBtn } = this.state;
        return (
            <React.Fragment>
                <body className="login_Bg">
                    <div className="overlay_body"></div>

                    <span className="loader loder_black">
                        <div className="logobox">
                            <img src={logobox} alt="" />
                        </div>
                    </span>
                    <section className="top_bar">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 pd-0 menubox">

                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-3 pd-0 main_head">
                                    <h4>Order Express</h4>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 pd-0 logo_box">
                                    <Link to="#"><img src={favicon} alt="" /></Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="login_box">
                        <div className="main_wrap login_page">
                            <Link to="/dashboard"><img src={favicon} alt="" /></Link>
                            <h3>Rider Login</h3>
                            <p>Please enter the requested information.</p>
                            <form onSubmit={this.submitForm} method="POST">
                                <div className="form_box">
                                    <span className="lnr lnr-envelope"></span>
                                    <input type="email" placeholder="Username or Email Adress " required="required" name="email" onChange={this.onChange} />
                                </div>
                                <div className="form_box">
                                    <span className="lnr lnr-lock"></span>
                                    <input type="password" placeholder="Password" required="required" name="password" onChange={this.onChange} />
                                </div>
                                <div className="form_box">
                                    <button
                                        type="submit"
                                        disabled={loadingBtn}
                                    >
                                        {loadingBtn && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {loadingBtn && 'Loading...'
                                        }
                                        {!loadingBtn && 'Sign In'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                </body>



            </React.Fragment>
        )
    }
}
export default Home;
